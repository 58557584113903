import React from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import WhiteLabel from "../svgs/top/whitelabel_img.svg"
import WhiteLabelSp from "../svgs/top/whitelabel_img_sp.svg"
const About = () => {
  return (
    <Layout headerCheck={false} page="about">
      <Seo
        title="わたしたちについて | becoz (ビコーズ)"
        description="DATAFLUCT (データフラクト) が運営する becoz (ビコーズ) についてご紹介。becoz はデータサイエンスの力で環境価値を見える化し、カーボンニュートラルに向けた行動を促進する環境価値流通プラットフォームです。"
      />
      <div className="main-wrap">
        <section className="section section-top">
          <div className="bg"></div>
          <div className="title">
            <p className="title-main">About Us</p>
            <p className="title-sub">わたしたちについて</p>
          </div>

          <div className="catch">
            <p className="title-main">
              Data <br className="sp-only" />
              Science
              <br />
              for Every <br className="sp-only" />
              Business
            </p>
          </div>
        </section>
        <section className="section section-our">
          <div className="box box01 anim base_anim">
            <div className="text-box">
              <p className="tag">Our Company</p>
              <p className="title-main">
                持続可能な未来を、
                <br className="pc-only" />
                アルゴリズムの共有で実現する
              </p>
              <p className="text-main">
                DATAFLUCTは、「”サステナブルアルゴリズム”<sup>※</sup>
                の社会実装」という経営理念を掲げています。
              </p>
              <p className="annotation">
                <sup>※</sup>
                サステナブルアルゴリズム”とは、「美しい社会と企業活動が100年後も持続できるアルゴリズム」のこと。
              </p>
              <Link to="https://datafluct.com/" target="_blank" className="btn">
                運営会社について
              </Link>
            </div>
            <div className="img-box anim">
              <StaticImage
                src="../images/about/img_our_01.jpg"
                placeholder="blurred"
                alt=""
              />
            </div>
          </div>
          <div className="box box02 anim base_anim">
            <div className="text-box">
              <p className="tag">Our Vision</p>
              <p className="title-main title-en">
                “impact wallet” for all,
                <br />
                “impact wallet” for the future
              </p>
              <p className="text-main">
                データサイエンスの力で、誰もが簡単に「環境価値」を可視化し、定量化できる“impact
                wallet”<sup>※</sup>を構想。
                <br className="sp-only" />
                生活者の意識を変え、行動を変えることを実現する「環境ポイント経済圏」を実現し、カーボンニュートラルな未来を共創することを目指します。
              </p>
              <p className="annotation">
                <sup>※</sup>“impact
                wallet”（商標登録済み）とは、個々人の環境負荷 /
                価値を、資産のように定量的にはかれる電子ウォレット。この環境負荷
                /
                価値は、CO2削減に止まらない。水使用削減や、プラスチック使用削減、リサイクル貢献度なども含む。
              </p>
            </div>
            <div className="img-box anim">
              <StaticImage
                src="../images/about/img_our_02.jpg"
                placeholder="blurred"
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="section section-card">
          <div className="box anim">
            <p className="num">01</p>
            <div className="line"></div>
            <p className="text-main anim base_anim">
              独自のテクノロジーと
              <br className="sp-only" />
              衛星データで
              <br className="pc-only" />
              環境価値を
              <br className="sp-only" />
              測る「ものさし」をつくる
            </p>
          </div>
          <div className="box anim">
            <p className="num">02</p>
            <div className="line"></div>
            <p className="text-main anim base_anim">
              生活者が環境価値を
              <br />
              「交換」できる選択肢を与える
            </p>
          </div>
          <div className="box anim">
            <p className="num">03</p>
            <div className="line"></div>
            <p className="text-main anim base_anim">
              環境価値を「生み出す」
              <br />
              選択肢を与える
            </p>
          </div>
        </section>
        <section className="section section-story">
          <div className="box box01 anim base_anim">
            <div className="text-box">
              <p className="tag">Our Mission</p>
              <p className="title-main">
                環境価値流通によって、
                <br className="pc-only" />
                「Green Engagement
                <br className="pc-only" />
                （＝環境消費 / 環境行動変容 / 環境投資）」
                <br className="pc-only" />
                を加速させる
              </p>
              <p className="text-main">
                「becoz」は現状を打破し、生活者を含むあらゆるステークホルダーがカーボンニュートラルに向けた取り組みに参加できる世界を実現するためのプラットフォームです。「環境価値」を、CO2排出削減量のみならず、取引可能な形にされたカーボンクレジット、プラスチックの消費削減量、水使用削減量など「地球のサステナビリティを担保するための、モノやコトが有する価値」として捉え、これを可視化し、個人の暮らしに新しい選択肢を提示します。排出量の削減行動などの形で個人が環境価値を生み出せる仕組みも備え、総合的な“環境価値”の流通プラットフォームを目指します。
              </p>
            </div>
            <div className="img-box">
              <StaticImage
                src="../images/about/img_story_01.jpg"
                placeholder="blurred"
                alt=""
              />
            </div>
          </div>
          <div className="box box02 anim base_anim">
            <div className="text-box">
              <p className="tag">Technology Detail</p>
              <p className="title-main">
                マルチモーダルデータを活用し、
                <br className="pc-only" />
                CO2排出量を総合的に可視化
              </p>
              <p className="text-main">
                多数のデータ活用事業を手がける当社は、画像、音声、テキストなど異なる様式のデータを統合的に処理するマルチモーダルデータ活用に強みを持ち、GPSの移動データや航空写真に基づく森林データなどからCO2排出量を算出するアルゴリズム構築の実績(
                <sup>※</sup>
                1)があります。「becoz」事業においても、クレジットカードの決済データだけではなく、レシート、ECサイト購入履歴などの購入店舗や商品まで特定でき、グリーンバリューチェーンプラットフォーム(
                <sup>※</sup>
                2)が提供する「排出源単位データベース」をもとにCO2排出量を可視化するデータ連携の仕組みの構築しております。
              </p>
              <p className="annotation">
                <sup>※</sup>1 詳細はプレスリリースをご参照ください。(
                2019年12月23日「森林所有者のための森林管理ツールの開発に向けた実証実験の開始について」
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://datafluct.com/release/261/"
                >
                  https://datafluct.com/release/261/
                </a>
                ）
                <br />
                （2021年1月29日『DATAFLUCT smartcity series.』提供開始
                <a
                  rel="noopener noreferrer"
                  href="https://datafluct.com/release/1353/"
                  target="_blank"
                >
                  https://datafluct.com/release/1353/
                </a>
                ）
                <br />
                <sup>※</sup>2
                環境省と経済産業省が運営する「温室効果ガスのサプライチェーン排出量算定（Scope1、Scope2、Scope3
                )・SBT・RE100・WMBなどの『脱炭素経営』に関する情報プラットフォーム」({" "}
                <a
                  rel="noopener noreferrer"
                  href="https://www.env.go.jp/earth/ondanka/supply_chain/gvc/index.html"
                  target="_blank"
                >
                  https://www.env.go.jp/earth/ondanka/supply_chain/gvc/index.html
                </a>{" "}
                ）
              </p>
            </div>
            <div className="img-box">
              <StaticImage
                src="../images/about/img_story_02.jpg"
                placeholder="blurred"
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="section section-service">
          <div className="bg-box">
            <StaticImage
              className="pc-only"
              src="../images/about/service_bg.jpg"
              placeholder="blurred"
              alt=""
            />
            <StaticImage
              className="sp-only"
              src="../images/about/service_bg.sp.jpg"
              placeholder="blurred"
              alt=""
            />
          </div>
          <div className="title-box  anim base_anim">
            <p className="title-main">Service</p>
            <p className="title-sub">サービスラインナップ</p>
          </div>
          <div className="service-box">
            <div className="list-wrap">
              <div className="item  anim base_anim">
                <div className="icon">
                  <StaticImage
                    src="../images/about/service_icon_04.png"
                    alt=""
                    placeholder="blurred"
                  />
                </div>
                <div className="text">
                  <div className="box">
                    <p className="title-main">becoz scope</p>
                    <p className="title-sub">
                      環境について
                      <br className="sp-only" />
                      学ぶ発信する
                    </p>
                  </div>
                </div>
              </div>
              <div className="item anim base_anim">
                <div className="icon">
                  <StaticImage
                    src="../images/about/service_icon_02.png"
                    alt=""
                    placeholder="blurred"
                  />
                </div>
                <div className="text">
                  <div className="box">
                    <p className="title-main">
                      becoz score /<br className="sp-only" /> becoz wallet
                    </p>
                    <p className="title-sub">
                      環境負荷を
                      <br className="sp-only" />
                      可視化する
                    </p>
                  </div>
                </div>
              </div>
              <div className="item anim base_anim">
                <div className="icon">
                  <StaticImage
                    src="../images/about/service_icon_05.png"
                    alt=""
                    placeholder="blurred"
                  />
                </div>
                <div className="text">
                  <div className="box">
                    <p className="title-main">
                      becoz challenge /<br className="sp-only" /> becoz point
                    </p>
                    <p className="title-sub">行動変容を促す</p>
                  </div>
                </div>
              </div>
              <div className="item anim base_anim">
                <div className="icon">
                  <StaticImage
                    src="../images/about/service_icon_06.png"
                    alt=""
                    placeholder="blurred"
                  />
                </div>
                <div className="text">
                  <div className="box">
                    <p className="title-main">becoz market</p>
                    <p className="title-sub">環境価値を流通させる</p>
                  </div>
                </div>
              </div>
              <div className="item anim base_anim">
                <div className="icon">
                  <StaticImage
                    src="../images/about/service_icon_01.png"
                    alt=""
                    placeholder="blurred"
                  />
                </div>
                <div className="text">
                  <div className="box">
                    <p className="title-main">
                      becoz credit /<br className="sp-only" /> becoz offset
                    </p>
                    <p className="title-sub">環境負荷をオフセットする</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link
            to="https://datafluct-21791923.hs-sites.com/becoz_toiawase"
            target="_blank"
            className="btn anim base_anim"
          >
            お問い合わせ
          </Link>
        </section>
        <section className="section section-howto">
          <div className="box box01">
            <div className="title-box anim base_anim">
              <p className="title-main">How To</p>
              <p className="title-sub">法人のお客様へ</p>
              <p className="text-main">
                環境 x 生活者 x 新規事業「環境意識の高い生活者の行動変容」を
                <br className="pc-only" />
                軸に新規事業創出を目指すパートナーを募集
              </p>
            </div>
          </div>
          <div className="box box02">
            <div className="box-wrap">
              <div className="title-box">
                <p className="title-main anim base_anim">White Label Partner</p>
                <p className="title-sub anim base_anim">
                  生活者の行動変容を実現する新規事業の開発や、
                  <br className="pc-only" />
                  既存プロダクトへの機能追加を行いたい
                </p>
                <div className="img-box anim base_anim sp-only">
                  <WhiteLabelSp />
                </div>
                <p className="text-sub anim base_anim">
                  「becoz」の機能をカスタマイズし、ご希望に合わせて個別開発して提供します。
                </p>
                <p className="text-main anim base_anim">
                  <span>
                    自社の抱える個人顧客向けに、気候変動や環境にまつわるサービスを提供したい
                  </span>
                  <span>
                    自社サービス、アプリにCO2排出量の可視化、オフセット機能を導入したい
                  </span>
                  <span>
                    その他、業界、企業特性に合わせた導入方法を提案いたします
                  </span>
                </p>
              </div>
              <div className="img-box anim base_anim pc-only">
                <WhiteLabel />
              </div>
            </div>
          </div>
          <div className="box box03">
            <div className="wrap">
              <div className="item">
                <p className="title-main anim base_anim">Platform Partner</p>
                <p className="title-sub anim base_anim">
                  生活者の環境貢献や、
                  <br />
                  行動変容に関する実証を行いたい
                </p>
                <p className="text-sub anim base_anim">
                  「becoz」のサービスを活用した実証実験や、
                  <br />
                  地域を限定した本格導入などを提案いたします。
                </p>
                <p className="text-main anim base_anim">
                  <span>
                    地域住民や学校法人を対象とした行動変容サービスを提供
                  </span>
                  <span>地域通貨等との連携アイデアを提案</span>
                  <span>
                    イベントや勉強会等の、エデュケーションにつながる企画も実施可能
                  </span>
                </p>
              </div>
              <div className="item">
                <p className="title-main anim base_anim">Engagement Partner</p>
                <p className="title-sub anim base_anim">
                  生活者に環境貢献につながるモノ・サービスや、
                  <br className="pc-only" />
                  新しいオフセットの手段を提供したい
                </p>
                <p className="text-sub anim base_anim">
                  「becoz」のプラットフォームやマーケティング連携にて、
                  <br className="pc-only" />
                  “環境先進層”に自社製品・サービスを訴求できます。
                </p>
                <p className="text-main anim base_anim">
                  <span>
                    SNSでのマーケティング連携から、本格的なイベント企画まで、幅広いオプションを提供
                  </span>
                  <span>
                    生活者がカーボンオフセットを行える数少ないサービスの一つである「becoz」で、さまざまなオフセットを実現
                  </span>
                  <span>ポイント事業者やEC事業者との将来的な連携も構想</span>
                </p>
              </div>
            </div>
            <div className="link-wrap anim base_anim">
              <div className="line"></div>
              <p className="text-main">Be our partners</p>
              <Link
                to="https://datafluct-21791923.hs-sites.com/becoz_toiawase"
                target="_blank"
                className="btn"
              >
                お問い合わせ
              </Link>
            </div>
          </div>
        </section>
        <section className="section section-app">
          <div className="text-box anim base_anim">
            <p className="title-sub">個人向けアプリ「becoz」の紹介</p>
            <div className="box">
              <div className="wrap">
                <p className="title-main">
                  <span className="en">becoz wallet</span>で
                  <br className="sp-only" />
                  カーボンニュートラルを
                  <br className="sp-only" />
                  日常に
                </p>
              </div>
            </div>
          </div>
          <div className="card-box">
            <Link
              to="https://wallet.becoz.ai"
              target="_blank"
              className="item anim"
              data-anim="is_slideIn"
            >
              <span className="title-main">wallet</span>
              <span className="title-sub">
                CO2排出削減量を
                <span>管理する</span>
              </span>
              <span className="text-main">
                個人の総排出量・削減
                量・オフセット量・をポートフォリオ化削減目標との乖離を管理
              </span>
              <StaticImage
                src="../images/top/img_app_01.png"
                placeholder="blurred"
                alt=""
              />
            </Link>
            <Link
              to="https://www.saisoncard.co.jp/lp/becoz/"
              target="_blank"
              className="item anim"
              data-anim="is_slideIn"
            >
              <span className="title-main">card</span>
              <span className="title-sub">
                消費者・企業の購買データを記録
                <span>見える化する</span>
              </span>
              <span className="text-main">
                利用明細データをbecoz
                walletに連携、購買データからCO2排出量を可視化できるカーボンニュートラル視点のクレジットカード
              </span>
              <StaticImage
                src="../images/top/img_app_02.png"
                placeholder="blurred"
                alt=""
              />
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default About
